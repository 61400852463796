import axios from 'axios';

import { templateConstants } from './templateConstants';

const removeLastCharacter = string => string.substring(0, string.length - 1);

const apiCallWrapper = requestParams => {
  let paramString = '?';
  Object.keys(requestParams).forEach(paramKey => {
    const parameterValue = requestParams[paramKey];
    if (paramKey === 'language') {
      if (parameterValue !== undefined && parameterValue !== '') paramString += `${paramKey}=${parameterValue}&`;
    } else if (parameterValue !== undefined) {
      paramString += `${paramKey}=${parameterValue}&`;
    }
  });
  return removeLastCharacter(paramString);
};

const languageFlatter = language => { if (language.length !== 0) return language[0]; };

export async function fetchCategories(templateType, language, source) {
  const { aliasType, getCustomReqParams = () => {} } = templateConstants[templateType];
  const templateTypeParameter = removeLastCharacter(aliasType || templateType);
  const requestParameters = apiCallWrapper({
    language: languageFlatter(language),
    ...getCustomReqParams(source)
  });
  return axios.get(`/API/${templateTypeParameter}/category${requestParameters}`);
}

const categoryParamFlatter = (selectedCategory, isMyTemplates, searchKeywordParameter) => {
  if (selectedCategory?.slug) return selectedCategory?.slug;
  if (!isMyTemplates && searchKeywordParameter === '') return 'homepage';
  return undefined;
};

export async function getTemplate(templateType, templateID, source = '', theme, forceProduction = false) {
  const {
    aliasType,
    getCustomPropertiesReqParams = () => ({ idKey: 'id' })
  } = templateConstants[templateType];

  const { idKey, ...rest } = getCustomPropertiesReqParams(source);
  const requestParameters = apiCallWrapper({
    ...rest,
    [idKey]: templateID,
    ...(theme === 'card' ? { theme } : {})
  });
  return axios.get(`${forceProduction ? 'https://www.jotform.com' : ''}/API/${(templateType === 'form-templates' ? 'form-template' : (aliasType || templateType))}${requestParameters}`);
}

export async function getCategory(filters, selectedCategory, language = ['en'], templateType, source) {
  const { theme = 'classic', sorting = 'popular' } = filters;
  const { aliasType, getCustomReqParams = () => {} } = templateConstants[templateType];
  const isFormTemplates = templateType === 'form-templates';
  const requestParameters = apiCallWrapper({
    ...getCustomReqParams(source),
    theme: isFormTemplates ? theme : undefined,
    sorting,
    filterBy: 'id',
    language: languageFlatter(language),
    id: selectedCategory._id || selectedCategory.id
  });
  return axios.get(`/API/${removeLastCharacter(aliasType || templateType)}/category${requestParameters}`);
}

export async function fetchStarterPackTemplates(payload = {}) {
  const { templateType, featuredTemplates = false } = payload;
  if (templateType === 'sign-templates') {
    return axios.get('/API/sign/starter-pack-templates');
  }

  return axios.get(`/API/${templateType}/starter-pack-templates`, {
    // A/B Test: templateSuggestionModalOnMyAppsIII
    params: {
      ...(featuredTemplates && { featuredTemplates: '1' })
    }
  });
}

export async function fetchTemplates(payload = {}) {
  const {
    source,
    filters,
    category,
    language,
    templateType,
    username = '',
    offsetParameter = 0,
    getOnlyMyTemplates = false,
    searchKeywordParameter = '',
    rpp = 24
  } = payload;
  const { sorting, theme } = filters;
  const isFormTemplates = templateType === 'form-templates';
  const { aliasType, getCustomReqParams = () => {} } = templateConstants[templateType];
  const requestParameters = apiCallWrapper({
    ...getCustomReqParams(source),
    theme: isFormTemplates ? theme : undefined,
    rpp,
    sorting: sorting,
    filterListing: 'all',
    start: offsetParameter,
    filterStatus: 'public',
    noESign: source === 'pdf-editor-new' ? '1' : '0',
    language: languageFlatter(language),
    username: getOnlyMyTemplates ? username : undefined,
    keyword: searchKeywordParameter !== '' ? searchKeywordParameter : undefined,
    category: categoryParamFlatter(category, getOnlyMyTemplates, searchKeywordParameter)
  });
  return axios.get(`/API/${aliasType || templateType}/filter${requestParameters}`);
}

export async function fetchLanguages(templateType, source) {
  const { aliasType, getCustomReqParams = () => {} } = templateConstants[templateType];
  const type = aliasType || templateType;
  const requestParameters = apiCallWrapper({
    ...getCustomReqParams(source)
  });
  return axios.get(`/API/${type}/languages${requestParameters}`);
}
